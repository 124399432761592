import React from "react";
import { useNavigate } from "react-router-dom";
import { locationicon, yearicon } from "../Custom/icons";

function JobHighlights({
  id,
  data,
  heading,
  isDataAvailable,
  istoggle,
  toggle,
}) {
  const navigate = useNavigate();
  const getJobDetail = (JobCode) => {
    navigate("/jobdetail", {
      state: {
        JobCode: JobCode,
        isMobileDevice: true,
      },
    });
  };
  const  pageNumber=1;
  const getJobPostingList = (code, organizationName) => {
    // queryParams send in url
    const queryParams = new URLSearchParams();
    queryParams.append("organization", code);
    const queryString = `?${queryParams.toString()}`;

    navigate(
      {
        pathname: "/jobs",
        search: queryString,
      },
      { state: { organizationName: organizationName, pageNumber } }
    );
  };

  return (
    <>
      <div className="jobs-section mb-3">
        {heading !== "" && (
          <div className="row">
            <h5 className="py-2 col-md-6 col-6">{heading}</h5>
            <div className="col-md-6 grid_list col-6">
              <i title="Tile View"
                              className={`fa fa-th-large me-2 facursor ${istoggle ? "d-none" : ""}`}
                onClick={() => toggle(!istoggle)}
              />
                          <i title="List View"
                              className={`fa fa-th-list facursor ${!istoggle ? "d-none" : ""}`}
                onClick={() => toggle(!istoggle)}
              />
            </div>
          </div>
        )}
        <div id={id} className="row">
          {isDataAvailable &&
            data.map((item, i) => (
              <div
                className={`item col-lg-${
                  istoggle ? "4 d-none d-lg-block" : "12 d-none d-lg-block"
                }`}
                key={i}
              >
                <div className="jobTuple hiringTuple">
                  <div className="jobTupleHeader dpflex ">
                    <div className="">
                      <span
                        className={`title jobTupleCursor fw600 ${istoggle ? "ellipsis" : ""}`}
                        onClick={() => getJobDetail(item.jobPostingId)}
                        title="Show Job Detail"
                      >
                        {item.jobTitle}
                      </span>
                      <div className="companyInfo  dpflex subheading lh22">
                        <span
                          className="subTitle ellipsis jobTupleCursor"
                          onClick={() =>
                            getJobPostingList(
                              item.organizationCode,
                              item.organizationName
                            )
                          }
                          title="Get Jobs By Company"
                        >
                          {item.organizationName}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="jobtags ">
                    <div className="job-description  dark-blue  ">
                      <i className="fleft icon-16 lh16 mr-10 cmp-jobdesc" />
                      <div className="fs13 ellipsis">
                        {yearicon}
                        {item.experienceRequired}
                      </div>
                    </div>
                  </div>
                  <div className="jobtags ">
                    <div className="job-description  dark-blue  ">
                      <i className="fleft icon-16 lh16 mr-10 cmp-jobdesc" />
                      <div className="fs13 ellipsis">
                        {locationicon}
                        {item.jobLocation}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div id={id} className="row d-lg-none">
          {isDataAvailable &&
            data.map((item, i) => (
              <div className={`item col-${istoggle ? "6" : "12"}`} key={i}>
                <div className="jobTuple hiringTuple">
                  <div className="jobTupleHeader dpflex ">
                    <div className="">
                      <span
                        className={`title fw600 ${istoggle ? "ellipsis" : ""}`}
                        onClick={() => getJobDetail(item.jobPostingId)}
                      >
                        {item.jobTitle}
                      </span>
                      <div className="companyInfo  dpflex subheading lh22">
                        <span
                          className="subTitle ellipsis"
                          onClick={() =>
                            getJobPostingList(
                              item.organizationCode,
                              item.organizationName
                            )
                          }
                        >
                          {item.organizationName}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="jobtags ">
                    <div className="job-description  dark-blue  ">
                      <i className="fleft icon-16 lh16 mr-10 cmp-jobdesc" />
                      <div className="fs13 ellipsis">
                        {yearicon}
                        {item.experienceRequired}
                      </div>
                    </div>
                  </div>
                  <div className="jobtags ">
                    <div className="job-description  dark-blue  ">
                      <i className="fleft icon-16 lh16 mr-10 cmp-jobdesc" />
                      <div className="fs13 ellipsis">
                        {locationicon}
                        {item.jobLocation}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default JobHighlights;
