import { encode } from "base-64";

const baseUrl = "https://jobapi-dev.nxgnservices.com/api/v";
//const baseUrl = "https://njapi.nxgnjobs.com/api/v";
//const baseUrl = "http://localhost:63246/api/v";
const ver = 1;
var domainUrl = null;
var headers = {};
var body = null;

function JoinURL(baseUrl, url) {
  return `${baseUrl}/${url}`;
}

class APIService {
  //Initialize API domain URL with API version'.
  constructor() {
    domainUrl = baseUrl + ver;
  }
  headers = {
    Accept: "application/json",
    Authorization: "none",
    userId: "",
  };
  //Generic method to generate a Javascript Fetch() call.
  request(url, method = "GET", data = null) {
    url = JoinURL(domainUrl, url);
    const options = {
      headers,
      method,
      body: data,
    };
    return fetch(url, options);
  }

  // Create functions like get(), post(), put(), delete() etc. as needed below.
  login(url, data) {
    const method = "POST";
      headers.Authorization = "Basic " + encode(data.email + ":" + data.password);
      headers.X_NXGNJOBS_AUTH = "7FE66769-449E-4781-84EB-D461DD6F4957";
      /*headers.X_NXGNJOBS_AUTH = "A16EDEFD-01E3-4AE5-9603-5B69BC73FE6B";*/
    return this.request(url, method, null).then((res) => res.json());
  }

  get(
    url,
    data,
    userId = null,
    header
  ) {
    const method = "GET";
    headers = header != null && header != undefined ? header : headers;
    headers.Authorization = "Bearer " + localStorage.getItem("token");
    
    return this.request(url, method, data).then((res) => res.json());
  }

  post(url, data, userId = null) {
    delete headers["content-type"];
    const method = "POST";
    if (localStorage.getItem("token") != null) {
      headers.Authorization = "Bearer " + localStorage.getItem("token");
    }
    return this.request(url, method, data).then((res) => res.json());
  }

  postBodyData(url, data, userId = null) {
    const method = "POST";
    headers.Authorization = "Bearer " + localStorage.getItem("token");
    headers["content-type"] = "application/json";
    return this.request(url, method, data).then((res) => res.json());
  }

  put(url, data) {
    const method = "PUT";
    return this.request(url, method, data).then((res) => res.json());
  }

  delete(url, data) {
    const method = "DELETE";
    headers.Authorization = "Bearer " + localStorage.getItem("token");
    headers["content-type"] = "application/json";
    return this.request(url, method, data).then((res) => res.json());
  }
}

export default APIService;
